import {
    getPrograms,
    getProgramById,
    getProgramsByTopicId,
    getProgramByDocumentProgramTopicId,
    getProgramByCompanyId,
    createProgram,
    editProgram,
    deleteProgram,
    // editProgramOverview,
    getMultiplesProgramsByDocTopProgId,
    getProgramStatus,
} from '../../modules/admin/program/services/program.service';

const programModule = {
    state: () => ({
        programs: [],
        program: [],//for 1 program
        programsFilteredByTopicByCompany: [],
        programsFilteredByCompany: [],
        selectedProgram: null,
        documentProgramTopicIds: [], // state documentProgramTopicIds for edit multiple Programs
        programsByDocTopProgIds: [], // state Programs By documentTopicProgramIds 
        
        programStatus: [], 

        //TopicProgramSort
        topicProgramSort: {
            sortField: null,
            sortOrder: null,
            firstPagValue: null,
            rowsPagValue: null,
            topicId: null,
            //filters
            status: null,
        },

        //ProgramFromTopicId
        programSortFromTopicId: {
            sortField: null,
            sortOrder :null,
            firstPagValue: null,
            rowsPagValue:null,
            topicId: null,
            //filters
            status: null,
        },
        
        //ProgramSort
        programSort : {
            sortField: null, 
            sortOrder: null,
            //filters
            status: null,
        } ,

        // this is the state for remember ProgramsColumns
        programsColumn : []

    }),
    getters: {
        obtainPrograms(state) {
            return state.programs;
        },
        obtainProgram(state) { //for 1 program 
            return state.program;
        },
        obtainProgramsFilteredByTopicIdByCompany(state){
            return state.programsFilteredByTopicByCompany
        },
        obtainProgramsFilteredByCompany(state){
            return state.programsFilteredByCompany
        },
        obtainSelectedProgram(state) {
            return state.selectedProgram;
        },
        obtainDocumentProgramTopicIds(state) { //obtain DocumeProgramTopicIds
            return state.documentProgramTopicIds;
        },
        obtainProgramByDocTopProgIds(state){ //obtain ProgramsByDocTopProgIds list
            return state.programsByDocTopProgIds;
        },
        obtainProgramStatus(state){
            return state.programStatus
        },

        //topicProgramSort
        obtainTopicProgramSort(state){
            return state.topicProgramSort;
        },

        // programSort from TopicId
        obtainProgramSortFromTopicId(state){
            return state.programSortFromTopicId;
        },
        
        //programSort 
        obtainProgramSort(state){
            return state.programSort; 
        },

        //getter for remember ProgramsColumns 
        obtainProgramsColumn(state){
            return state.programsColumn
        }, 
    },
    mutations: {
        setPrograms(state, programs) {
            state.programs = programs;
        },
        setProgram(state, program) { //for 1 program 
            state.program = program;
        },
        setDocumentProgramTopicIds(state, documentProgramTopicIds) {//set DocumeProgramTopicIds
            state.documentProgramTopicIds = documentProgramTopicIds ;
        },
        cleanDocumentProgramTopicIds(state){
            state.documentProgramTopicIds = null; 
        },
        setProgramsByDocTopProgIds(state, programs){ //set Programs By DocumentTopicProgramIds
            state.programsByDocTopProgIds = programs 
        },
        addProgram(state, program) {
            state.programs.push(program);
        },
        updateProgram(state, program) {
            state.programs = [
                ...state.programs.filter((d) => d.documentProgramId !== program.documentProgramId),
                program,
            ];
        },
        deleteProgram(state, programId) {
            state.programs = [
                ...state.programs.filter((d) => d.documentProgramId !== programId),
            ];
        },

        // programsFilterdBy topic and Companies
        setProgramsFilteredByTopicIdByCompany(state, programs){
            state.programsFilteredByTopicByCompany = programs
        },

        // programsFilteredBy Companies 
        setProgramsFilteredByCompany(state, programs){
            state.programsFilteredByCompany = programs;
        },
        deleteProgramFilteredByCompany(state,programId){
            state.programsFilteredByCompany = [
                ...state.programsFilteredByCompany.filter((d) => d.documentProgramId !== programId),
            ];
        },

        setSelectedProgram(state, selectedProgram) {
            state.selectedProgram = selectedProgram;
        },
        cleanSelectedProgram(state) {
            state.selectedProgram = null
        },

        // programStatus
        setProgramStatus(state, programStatus){
            state.programStatus = programStatus
        },

        //topicProgramSort
        updateTopicProgramSort(state, topicProgramSort){
            state.topicProgramSort = topicProgramSort
        },

        updateTopicProgramSortFilters(state, topicProgramSortFilter){
            state.topicProgramSort.topicId = topicProgramSortFilter.topicId;
            state.topicProgramSort.status = topicProgramSortFilter.status;
        },
        //programSortFrom TopicId
        updateProgramSortFromTopicId(state, programSortFromTopicId){
            state.programSortFromTopicId = programSortFromTopicId
        },
        updateProgramSortFromTopicIdFilters(state, programSortFromTopicIdFilter){
            state.programSortFromTopicId.topicId = programSortFromTopicIdFilter.topicId,
            state.programSortFromTopicId.status = programSortFromTopicIdFilter.status

        },

        // programSort
        updateProgramSort(state, programSort){
            state.programSort = programSort
        },

        //mutation for Remember ProgramsColumn 
        updateProgramsColumn(state, programsColumn){
            state.programsColumn = programsColumn
        },
        cleanProgramsColumn(state){
            state.programsColumn =  []
        },

    },
    actions: {
        async obtainPrograms({ commit }, countryId) {
            const programs = await getPrograms(countryId);
            commit('setPrograms', programs);
        },
        async obtainProgramById({ commit }, programId) {
            const program = await getProgramById(programId);
            commit('setProgram', program)
        },
        async obtainProgramsByTopicId({ commit }, { topicId, withDetails, withSegItems ,totalDocuments }) {
            // console.log(topicId);
            const programs = await getProgramsByTopicId(topicId, withDetails,withSegItems, totalDocuments);
            // console.log(programs);
            if (programs === null) {
                // console.log('nohay :(');
                commit('setPrograms', [])
            }
            else {
                // console.log('si hay  :)');
                commit('setPrograms', programs)
            }
        },
        async obtainProgramByDocumentProgramTopic({ commit }, documentProgramTopicId) {
            const program = await getProgramByDocumentProgramTopicId(documentProgramTopicId);
            commit('setProgram', program)
        },
        // TODO WHEN ENDPOINT FINISH CHECK CODE 
        async obtainProgramsFilteredByTopicIdByCompany({ commit },{companyId , topicId} ){
            const program = await getProgramByCompanyId(companyId , topicId);
            commit('setProgramsFilteredByTopicIdByCompany',program);
        },
        resetProgramsFilteredByTopicIdByCompany({commit},){
            commit('setProgramsFilteredByTopicIdByCompany',[] );
        },
        // TODO WHEN ENDPOINT fINISH CHECK CODE 
        async obtainProgramsFilteredByCompany({commit},{companyId} ){
            // add this to parameters 
            // { topicId, withDetails, totalDocuments , companyId}
            const programs = await getProgramByCompanyId(companyId);
            commit('setProgramsFilteredByCompany',programs);
        },
        resetProgramsFilteredByCompany({commit},){
            commit('setProgramsFilteredByCompany');
        },
        async addNewProgram({ commit }, program) {
            const response = await createProgram(program);
            commit('addProgram', response.data);
        },
        async modifyProgram({ commit }, program) {
            const programId = program.documentProgramId
            const response = await editProgram(programId, program);
            commit('updateProgram', response.data);
        },
        async removeProgram({ commit }, programId) {
            await deleteProgram(programId);
            commit('deleteProgram', programId);
        },
        updateSelectedProgram({ commit }, programId) {
            commit('setSelectedProgram', programId)
        },
        cleanSelectedProgram({ commit }) {
            commit('cleanSelectedProgram')
        },
        // multiple documentsProgramTopicIds
        addDocumentProgramTopicIds({ commit }, documentProgramTopicIds) {
            localStorage.setItem('docProgTopIds-mfkb',JSON.stringify(documentProgramTopicIds));
            commit('setDocumentProgramTopicIds', documentProgramTopicIds);
        },
        cleanDocumentProgramTopicIds({commit}){
            localStorage.setItem('docProgTopIds-mfkb', null);
            commit('cleanDocumentProgramTopicIds');
        },
        async obtainProgramsByDocTopProgIds({ commit } , documentProgramTopicIds){
            const programs = await getMultiplesProgramsByDocTopProgId(documentProgramTopicIds);
            commit('setProgramsByDocTopProgIds', programs);
        },

        async obtainProgramStatus({commit}){
            const response = await getProgramStatus(); 
            commit('setProgramStatus',response);
        },

        //TopicProgramSort
        updateTopicProgramSort({commit}, topicProgramSort){
            commit('updateTopicProgramSort', topicProgramSort)
        },

        updateTopicProgramSortFilters({commit}, topicProgramFilters){
            commit('updateTopicProgramSortFilters',topicProgramFilters);
        },

        // programSort From TopicId
        updateProgramSortFromTopicId({commit}, programSortFromTopicId){
            commit('updateProgramSortFromTopicId',programSortFromTopicId)
        },
        updateProgramSortFromTopicIdFilters({commit}, programSortFromTopicIdFilters){
            
            
            commit('updateProgramSortFromTopicIdFilters',programSortFromTopicIdFilters);
        },

        //Program Sort
        updateProgramSort({commit}, programSort){
            commit('updateProgramSort', programSort)
        },

        // actions for remember ProgramsColumn 
        updateProgramsColumn({commit}, programsColumn){
            commit('updateProgramsColumn', programsColumn)
        },
        cleanProgramsColumn({commit}){
            commit('cleanProgramsColumn')

        }
    },
};

export default programModule;
