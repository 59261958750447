import { axiosProvider } from '../../../../shared/config/axios.config';

async function getPrograms(countryId) {
    if(countryId){
        try {
            const response = await axiosProvider.get(`/documentPrograms?countryId=${countryId}`);
            const documentPrograms = response.data;
            return documentPrograms;
        } catch (error) {
            return null
        }
    }
    else{
        const response = await axiosProvider.get('/documentPrograms');
        const documentPrograms = response.data;
        return documentPrograms;
    }
}
async function getProgramById(programId){
    const response = await axiosProvider.get(`/documentPrograms/${programId}`)
    const documentProgram = response.data;
    return documentProgram;
}
async function getProgramsByTopicId(topicId , withDetails , withSegItems , totalDocuments){
    const response = await axiosProvider.get(`/documentPrograms/topics`, {params: {topicId: topicId , withDetails: withDetails, withSegItems: withSegItems , totalDocuments:totalDocuments }})
    if(response){
        const documentPrograms = response.data;
        return documentPrograms; 
    }
    else{
        const documentPrograms = null;
        return documentPrograms; 
    }
}
async function getProgramByDocumentProgramTopicId(DocumentProgramTopicId){
    const response = await axiosProvider.get(`/documentPrograms/topics/${DocumentProgramTopicId}`)
    const documentProgram = response.data
    return documentProgram
}

async function getProgramByCompanyId(companyId , topicId){
    let response ;
    if(topicId){
        response = await axiosProvider.get(`/documentprograms/companies/${companyId}?topicId=${topicId}`)
        const programs = response.data; 
        return programs
    }
    else{
        response = await axiosProvider.get(`/documentprograms/companies/${companyId}`)
        const programs = response.data;
        return programs
    }
}

async function createProgram(newDocumentProgram) {
    const response = await axiosProvider.post('/documentPrograms', newDocumentProgram);
    const documentProgram = response.data;
    return documentProgram;
}

async function editProgram(documentProgramId, editedDocumentProgram) {
    delete editedDocumentProgram.documentProgramId;
    // console.log(editedDocumentProgram);
    const response = await axiosProvider.put(`/documentPrograms/${documentProgramId}`, editedDocumentProgram);
    const documentProgram = response.data;
    return documentProgram;
}

async function deleteProgram(documentProgramId) {
    const response = await axiosProvider.delete(`/documentPrograms/${documentProgramId}`);
    const documentProgram = response.data;
    return documentProgram;
}

async function editProgramOverview(DocumentProgramTopicId ,program){
    // delete program.documentProgramId;
    // console.log(DocumentProgramTopicId)
    // console.log(typeof JSON.stringify(program))
    const response = await axiosProvider.put(`/documentPrograms/topics/${DocumentProgramTopicId}`,  program)
    const documentProgram = response.data
    return documentProgram
}

// get list of MultiplesPrograms By DocumentTopicProgramIds service
async function getMultiplesProgramsByDocTopProgId( docTopProgIds ){
    const response = await axiosProvider.post(`/documentPrograms/topics/list`, docTopProgIds);
    const documentPrograms = response.data;
    return documentPrograms;
}

//ProgramStatus
async function getProgramStatus(){
    const response = await axiosProvider.get('/documentPrograms/programStatus');
    return response.data
} 

export {
    getPrograms,
    getProgramById,
    getProgramsByTopicId,
    getProgramByDocumentProgramTopicId,
    getProgramByCompanyId,
    createProgram,
    editProgram,
    deleteProgram,
    editProgramOverview,
    getMultiplesProgramsByDocTopProgId,
    getProgramStatus,
}
