<template>
  <footer class="container-fluid bg-secondary text-light">
    <div class="row p-2">
      <div class="col-8 d-flex justify-content-start">ASD Media</div>

      <div class="col-4 d-flex justify-content-end">© {{ currentYear }}</div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return { currentYear: new Date().getFullYear() };
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped>
footer {
  position: relative;
  bottom: 0px;
}
</style>
