import { marketDataRoutes } from './market-data/market-data.route';
import { companiesRoutes } from './company/company.route';
import { countriesRoutes } from './country/country.route';
import { accessControlRoutes} from './accessControl/accessControl.route';
// admin/
export const adminRoutes = [
  {
    path: 'topics',
    name: 'Topics',
    component: () => import('./market-data/MarketData.page.vue'),
    children: marketDataRoutes,
  },
  {
    path: 'companies',
    name: 'Companies',
    component: () => import('./company/Company.page.vue'),
    children: companiesRoutes,
  },
  {
    path: 'documents',
    name: 'Documents',
    component: () => import('./document/Document.page.vue'),
  },
  {
    path: 'topicListDocuments',
    name: 'topicListDocuments',
    component: () => import('./document/TopicDocument.page.vue'),
  },
  {
    path: 'programs',
    name: 'Programs',
    component: () => import('./program/Program.page.vue'),
  },
  {
    path: 'programs/edit',
    name: 'EditPrograms',
    component: () => import('./program/EditPrograms.page.vue'), // edit multiple Programs
  },
  {
    path: 'access',
    name: 'Access',
    component: ()=> import('./accessControl/AccessControl.page.vue'),
    children: accessControlRoutes,
  },
  {
    path: 'categories',
    name: 'Categories',
    component: () => import('./category/Category.page.vue'),
  },
  {
    path: 'regions',
    name: 'Regions',
    component: () => import('./region/Region.page.vue'),
  },
  {
    path: 'countries',
    name: 'Countries',
    component: () => import('./country/Country.page.vue'),
    children: countriesRoutes,
  },
];
