import { createStore } from 'vuex';
import topicModule from './modules/topic.module';
import countryModule from './modules/country.module';
import regionModule from './modules/region.module';
import authModule from './modules/auth.module';
import documentModule from './modules/document.module';
import programModule from './modules/program.module';
import companyModule from './modules/company.module';
import categoryModule from './modules/category.module';
import userModule from './modules/user.module';
import marketDataModule from './modules/marketData.module';
import segmentationModule from './modules/segmentation.module';
import taggingModule from './modules/tagging.module';
import scenarioModule from './modules/scenario.module';
import exportOutputModule from './modules/exportOutput.module';

const store = createStore({
  modules: {
    tagging: taggingModule,
    segmentation: segmentationModule,
    company: companyModule,
    user: userModule,
    document: documentModule,
    topic: topicModule,
    country: countryModule, 
    region: regionModule,
    marketData: marketDataModule,
    category:categoryModule,
    auth: authModule,
    program: programModule,
    scenario: scenarioModule,
    exportOutput: exportOutputModule,
  },
});

export default store;
