import { axiosProvider } from '../../../../shared/config/axios.config';

async function getSegmentationsByTopicId(topicId) {
  const response = await axiosProvider.get(`/segmentations?topicId=${topicId}`);
  let data = [];
  if (response) {
    data = response.data;
  }
  return data;
}

async function getSegmentationById(segmentationId) {
  const response = await axiosProvider.get(`/segmentations/${segmentationId}`);
  const data = response.data;
  return data;
}

async function createSegmentation(segmentation) {
  const response = await axiosProvider.post('/segmentations', segmentation);
  const data = response.data;
  return data;
}

async function createSegmentationItem(segmentationItem) {
  const response = await axiosProvider.post(
    '/segmentationItems',
    segmentationItem
  );
  const data = response.data;
  return data;
}

async function editSegmentationItem(segmentationItemId, segmentationItem) {
  segmentationItem.segmentation = null;
  const response = await axiosProvider.put(
    `/segmentationItems/${segmentationItemId}`,
    segmentationItem
  );
  const data = response.data;
  return data;
}

async function editSegmentationOrder(segmentationId, goUp) {
  // console.log(segmentationId);
  // console.log(goUp);
  const response = await axiosProvider.put(
    `/segmentations/${segmentationId}/changeOrder?goUp=${goUp}`
  );
  const data = response.data;
  return data;
}

async function editSegmentationItemOrder(segmentationItemId, goUp){
  const response = await axiosProvider.put(
    `/segmentationItems/${segmentationItemId}/changeOrder?goUp=${goUp}`
  )
  const data = response.data
  return data;
}

async function deleteSegmentationItem(segmentationItemId) {
  const response = await axiosProvider.delete(
    `/segmentationItems/${segmentationItemId}`
  );
  const data = response.data;
  return data;
}

async function editSegmentation(segmentationId, segmentation) {
  delete segmentation.segmentationId;
  segmentation.topic = null;
  const response = await axiosProvider.put(
    `/segmentations/${segmentationId}`,
    segmentation
  );
  const data = response.data;
  return data;
}

async function deleteSegmentation(segmentationId) {
  const response = await axiosProvider.delete(
    `/segmentations/${segmentationId}`
  );
  const data = response.data;
  return data;
}

export {
  getSegmentationsByTopicId as getSegmentations,
  getSegmentationById,
  createSegmentation,
  editSegmentation,
  deleteSegmentation,
  createSegmentationItem,
  editSegmentationItem,
  deleteSegmentationItem,
  editSegmentationOrder,
  editSegmentationItemOrder,
};
