import { getScenarioByScenarioId, getScenariosByTopicId, createScenario, editScenario, deleteScenario, } from '../../modules/admin/topic/services/topic.service'

const scenarioModule = {
    state: () => ({
        scenarios: [],
    }),
    getters: {
        obtainScenarios(state) {
            return state.scenarios;
        },
    },
    mutations: {
        setScenariosIfError(state){
            state.scenarios = []
        },
        setScenarios(state, scenarios) {
            state.scenarios = scenarios
        },
        addScenario(state, scenario) {
            state.scenarios.push(scenario)
        },
        updateScenario(state, scenario) {
            state.scenarios = [
                ...state.scenarios.filter((item) => item.scenarioId !== scenario.scenarioId),
                scenario,
            ]
        },
        deleteScenario(state, scenarioId) {
            state.scenarios = [
                ...state.scenarios.filter((item) => item.scenarioId !== scenarioId),
            ];
        },
    },
    actions: {
        async obtainScenarios({ commit }, scenarioId) {
            const scenarios = await getScenarioByScenarioId(scenarioId);
            commit('setScenarios', scenarios)
        },
        async obtainScenariosByTopicId({ commit }, topicId) {
            const scenarios = await getScenariosByTopicId(topicId)
            commit('setScenarios', scenarios)
        },
        async addScenario({ commit }, scenario) {
            const response = await createScenario(scenario);
            commit('addScenario', response.data)
        },
        async modifyScenario({ commit }, scenario) {
            const response = await editScenario(scenario.scenarioId, scenario);
            commit('updateScenario', response.data);
        },
        async removeScenario({ commit }, scenarioId) {
            await deleteScenario(scenarioId);
            commit('deleteScenario', scenarioId)
        },
    },
};

export default scenarioModule;
