import { axiosProvider } from '../../shared/config/axios.config';

async function login(user) {
  const response = await axiosProvider.post('/auth/login', user);
  return response.data;
}

async function register(newUser) {
  const response = await axiosProvider.post('/auth/signup', newUser);
  return response.data;
}

async function resetPassword(recoveryCredentials) {
  const response = await axiosProvider.post(
    '/auth/reset-password',
    recoveryCredentials
  );
  return response.data;
}

async function confirmNewPassword(newUserCredentials) {
  const response = await axiosProvider.post(
    '/auth/confirm-password',
    newUserCredentials
  );
  return response.data;
}

export { login, register, resetPassword, confirmNewPassword };
