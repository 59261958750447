import { axiosProvider } from '../../../../shared/config/axios.config';

async function getUsers() {
  const response = await axiosProvider.get('/users?topicsCount=true');
  const data = response.data;
  return data;
}

async function getUserById(userId) {
  const response = await axiosProvider.get(`/users/${userId}`);
  const data = response.data; 
  return data 
}

async function createUser(user){
  const response = await axiosProvider.post(`/users`, user);
  // const response = await axiosProvider.post(`/auth/signup`, user);
  const data = response.data;
  return data
}

async function updateUser(user , userId) {
  const response = await axiosProvider.put(`/users/${userId}`, user);
  const data = response.data;
  return data;
}

async function deleteUser(userId){
  const response = await axiosProvider.delete(`/users/${userId}`);
  const data = response.data; 
  return data 
}

async function updateUserSelectedTopic(topicId) {
  const response = await axiosProvider.patch(`/users/topic/${topicId}`);
  const data = response.data;
  return data;
}

async function getUserRoles(){
  const response = await axiosProvider.get('/users/roles')
  const data = response.data;
  return data
} 

export { getUsers, getUserById, createUser,  updateUser,deleteUser ,updateUserSelectedTopic , getUserRoles };
