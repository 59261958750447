// admin/companies/
export const companiesRoutes = [
    {   
        path:'',
        name:'CompaniesList',
        component: () => import('./components/CompaniesList.vue'),
    },
    {
        path: 'company/new',
        name: 'CreateCompany',
        component: () => import('./pages/createCompany.page.vue'),
    },
    {
        path: 'company/:companyId',
        name: 'EditCompany',
        component: () => import('./pages/editCompany.page.vue'),
    },
]