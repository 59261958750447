// /admin/access/

export const accessControlRoutes= [
    {
        path:'',
        name:'accessControl',
        component:()=> import ('./pages/AccessControlList.page.vue')
    },
    {
        path:'newUser',
        name:'signNewUser',
        component:()=> import('../../../views/Signup.vue')
    },
    {
        path:'user/:userId',
        name:'accessControlUser',
        component:() => import('./pages/AccessControlUserDetail.page.vue')
    },
]
