<template>
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-12 col-md-6 py-4">
        <form @submit.prevent="onSubmit">
          <h1 class="h3 mb-4 fw-normal">Please sign in</h1>

          <div class="form-floating mb-4">
            <input
              type="email"
              class="form-control"
              id="floatingInput"
              placeholder="name@example.com"
              autocomplete="off"
              required
              v-model="userForm.email"
              @blur="v$.userForm.email.$touch"
            />
            <label for="floatingInput">Email address</label>
            <span v-if="v$.userForm.email.$error" class="text-danger p-2">
              {{ v$.userForm.email.$errors[0].$message }}
            </span>
          </div>

          <div class="form-floating mb-4">
            <input
              type="password"
              class="form-control"
              id="floatingPassword"
              placeholder="Password"
              autocomplete="off"
              required
              v-model="userForm.password"
              @blur="v$.userForm.password.$touch"
            />
            <label for="floatingPassword">Password</label>
            <span v-if="v$.userForm.password.$error" class="text-danger p-2">
              {{ v$.userForm.password.$errors[0].$message }}
            </span>
          </div>

          <button
            @click="login()"
            class="w-100 btn btn-lg btn-outline-primary"
            type="button"
            :disabled="this.v$.userForm.$invalid || loading"
          >
            <span
              v-if="loading"
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            {{ loading ? 'Loading...' : 'Login' }}
          </button>
        </form>
      </div>
    </div>

    <div class="row d-flex justify-content-center">
      <div class="col-6 d-flex justify-content-center">
        <!-- Button trigger modal -->
        <button
          type="button"
          class="btn btn-outline-dark btn-sm"
          data-bs-toggle="modal"
          data-bs-target="#recoveryModal"
          @click="setRecoveryEmail()"
        >
          Reset your password.
        </button>

        <!-- Modal -->
        <div
          class="modal fade"
          id="recoveryModal"
          tabindex="-1"
          aria-labelledby="recoveryModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="recoveryModalLabel">
                  Please, enter your user credentials:
                </h5>

                <button
                  id="closeRecoveryModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div class="modal-body">
                <div
                  class="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingOne">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        Your recovery email address:
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      class="accordion-collapse collapse show"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        <div class="form-floating mb-4">
                          <input
                            type="email"
                            class="form-control"
                            id="floatingInputRecovery"
                            placeholder="name@example.com"
                            autocomplete="off"
                            required
                            v-model="recoveryForm.recoveryEmail"
                            @blur="v$.recoveryForm.recoveryEmail.$touch"
                          />
                          <label for="floatingInputRecovery"
                            >Email address</label
                          >
                          <span
                            v-if="v$.recoveryForm.recoveryEmail.$error"
                            class="text-danger p-2"
                          >
                            {{
                              v$.recoveryForm.recoveryEmail.$errors[0].$message
                            }}
                          </span>
                        </div>

                        <div
                          class="d-grid gap-2 d-md-flex justify-content-md-end"
                        >
                          <button
                            @click="passwordRecovery()"
                            type="button"
                            class="btn btn-outline-success"
                            :disabled="
                              this.v$.recoveryForm.recoveryEmail.$invalid
                            "
                          >
                            {{
                              recoveryEmailSent
                                ? 'Send again'
                                : 'Send recovery code'
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        Your recovery credentials:
                      </button>
                    </h2>

                    <div
                      id="flush-collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingTwo"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        <div class="form-floating mb-4">
                          <input
                            type="text"
                            class="form-control"
                            id="floatingInputRecoveryCode"
                            autocomplete="off"
                            required
                            v-model="recoveryForm.recoveryCode"
                            @blur="v$.recoveryForm.recoveryCode.$touch"
                          />
                          <label for="floatingInputRecoveryCode"
                            >Recovery code</label
                          >
                          <span
                            v-if="v$.recoveryForm.recoveryCode.$error"
                            class="text-danger p-2"
                          >
                            {{
                              v$.recoveryForm.recoveryCode.$errors[0].$message
                            }}
                          </span>
                        </div>

                        <div class="form-floating mb-4">
                          <input
                            type="password"
                            class="form-control"
                            id="floatingInputRecoveryPassword"
                            placeholder="Password"
                            autocomplete="off"
                            required
                            v-model="recoveryForm.newPassword"
                            @blur="v$.recoveryForm.newPassword.$touch"
                          />
                          <label for="floatingInputRecoveryPassword"
                            >New password</label
                          >
                          <span
                            v-if="v$.recoveryForm.newPassword.$error"
                            class="text-danger p-2"
                          >
                            {{
                              v$.recoveryForm.newPassword.$errors[0].$message
                            }}
                          </span>
                        </div>

                        <div
                          class="d-grid gap-2 d-md-flex justify-content-md-end"
                        >
                          <button
                            @click="confirmPassword()"
                            type="button"
                            class="btn btn-outline-success"
                            :disabled="this.v$.recoveryForm.$invalid"
                          >
                            Confirm Password
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  resetPassword,
  confirmNewPassword,
} from '@/shared/services/auth.service';
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import { useToast } from 'vue-toastification';
const toast = useToast();

export default {
  name: 'Login',
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      userForm: {
        email: null,
        password: null,
      },
      recoveryForm: {
        recoveryCode: null,
        newPassword: null,
        recoveryEmail: null,
      },
      loading: false,
      recoveryEmailSent: false,
    };
  },
  validations() {
    return {
      userForm: {
        email: { required, email },
        password: { required },
      },
      recoveryForm: {
        recoveryCode: { required },
        newPassword: { required },
        recoveryEmail: { required, email },
      },
    };
  },
  mounted() {
    this.logoutUser();
  },
  methods: {
    async login() {
      try {
        if (!this.v$.userForm.$invalid) {
          this.loading = true;
          await this.$store.dispatch('loginUser', this.userForm);
          this.loading = false;
          toast.success(`Welcome ${this.userForm.email}`);
          this.$router.push({path: '/admin/topics/select-topic'})
          // this.$router.push({
          //   name: 'TopicDocuments',
          //   params: { fromParent: true },
          // });
        } else {
          toast.error('Wrong user credentials');
        }
      } catch (error) {
        toast.error(error.message);
        this.loading = false;
      }
    },
    logoutUser() {
      this.$store.dispatch('logoutUser');
      this.$router.push({ path: '/login' });
    },
    async passwordRecovery() {
      try {
        if (!this.v$.recoveryForm.recoveryEmail.$invalid) {
          const recoveryResponse = await resetPassword({
            email: this.recoveryForm.recoveryEmail,
          });
          toast.success(recoveryResponse.message);
          this.recoveryEmailSent = true;
        } else {
          toast.error('Wrong user credentials');
        }
      } catch (error) {
        toast.error(error.message);
      }
    },
    async confirmPassword() {
      try {
        if (!this.v$.recoveryForm.$invalid) {
          const resetPasswordResponse = await confirmNewPassword({
            email: this.recoveryForm.recoveryEmail,
            recoveryCode: this.recoveryForm.recoveryCode,
            newPassword: this.recoveryForm.newPassword,
          });
          toast.success(resetPasswordResponse.message);
          const closeButton = document.getElementById('closeRecoveryModal');
          closeButton.click();
        } else {
          toast.error('Wrong user credentials');
        }
      } catch (error) {
        toast.error(error.message);
      }
    },
    setRecoveryEmail() {
      if (!this.v$.userForm.email.$error) {
        this.recoveryForm.recoveryEmail = this.userForm.email;
      }
    },
  },
};
</script>

<style scoped></style>
