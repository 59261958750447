import {
    getCountries,
    getFilteredCountries,
    getCountryByCountryId,
    // getCountriesByRegionId,
    // createCountry,
    editCountry,
    // deleteContry,
    getProgramsPerCountry,
} from '../../modules/admin/country/services/country.service';

const countryModule = {
    state: () => ({
        countries: [],
        country: [],
        programsPerCountry: [], // state Programs per Country 
        adminCountrySort: {
            sortField: "name",
            sortOrder: 1,
            firstPagValue: null,
            rowsPagValue: null,
        },
        countrySort : {
            sortField: "name",
            sortOrder: 1,
            firstPagValue: null,
            rowsPagValue: null,
            priority:true,
        }
    }),
    getters: {
        obtainCountries(state) {
            return state.countries;
        },
        obtainCountry(state) {
            return state.country;
        },
        obtainProgramsPerCountry(state) { //obtain ProgramsPerCountry list
            return state.programsPerCountry;
        },
        obtainAdminCountrySort(state) {
            return state.adminCountrySort;
        },
        obtainCountrySort(state){
            return state.countrySort;
        }
        
    },
    mutations: {
        setCountries(state, countries) {
            state.countries = countries;
        },
        setCountry(state, country) {
            state.country = country;
        },
        // addCountry(state, country) {
        //     state.countries.push(country);
        // },
        updateCountry(state, country) {
            state.country = [
                ...state.countries.filter((t) => t.countryId !== country.countryId),
                country
            ];
        },
        // deleteCountry(state, countryId) {
        //     state.countries = [
        //         ...state.countries.filter((t) => t.countryId !== countryId),
        //     ];
        // },
        setProgramsPerCountry(state, programs) { //set Programs Per Country, 
            state.programsPerCountry = programs
        },

        // admin countrySort
        updateAdminCountrySort(state, adminCountrySort) {
            state.adminCountrySort = adminCountrySort
        },
        //countrySort
        updateCountrySort(state, countrySort){
            state.countrySort = countrySort
        }
    },
    actions: {
        async obtainCountries({ commit }) {
            const countries = await getCountries();
            commit('setCountries', countries);
        },
        async obtainFilteredCountries({ commit }, { totalDocuments, totalPrograms, hasPriority }) {
            const countries = await getFilteredCountries(totalDocuments, totalPrograms, hasPriority);
            commit('setCountries', countries);
        },
        async obtainCountry({ commit }, countryId) {
            const country = await getCountryByCountryId(countryId);
            commit('setCountry', country)
        },
        // async obtainCountriesByRegion({commit}){
        //     const countries = await getCountriesByRegionId();
        //     commit('setCountries', countries);
        // },
        // async addNewCountry({ commit }, country) {
        //     const response = await createCountry(country);
        //     commit('addCountry', response);
        // },
        async modifyCountry({ commit }, country) {
            console.log(country);
            const response = await editCountry(country.countryId, country);
            commit('updateCountry', response);
        },
        // async removeCountry({ commit }, countryId) {
        //     await deleteContry(countryId);
        //     commit('deleteCountry', countryId)
        // },
        // programs Per Country
        async obtainProgramPerCountry({ commit }, topicId) { // for Programs Per Country
            const programPCountry = await getProgramsPerCountry(topicId);
            commit('setProgramsPerCountry', programPCountry)
        },

        //adminCountrySort
        updateAdminCountrySort({ commit }, adminCountrySort) {
            commit('updateAdminCountrySort', adminCountrySort)

        },
        updateCountrySort({commit}, countrySort){
            commit('updateCountrySort',countrySort)
        }
    },
};
export default countryModule; 
