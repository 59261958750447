import {
  getMarketData,
  getMarketDataFusion,
} from '../../modules/admin/market-data/services/marketData.service';

const marketDataModule = {
  state: () => ({
    marketDataModel: {},
    currentFocus: {
      datasetTypeId: 2, // tab -> We default to Requirements on load
      segmentedDataIndex: '', // segmented data index - Region = 0, rest are 1 based index on the segmentation foreach.
      isPercentage: false, // is it the percentage segmented data. False unless percentage in the trend tab.
      regionId: '',
      childSegItemId: '',
      year: '',
    },
  }),
  getters: {
    model(state) {
      return state.marketDataModel;
    },
    currentFocus(state) {
      return state.currentFocus;
    },
  },
  actions: {
    async initModel({ commit }) {
      const response = await getMarketData();
      commit('setMarketDataModel', response);
    },
    async refreshFusion({ commit }, payload) {
      var regionalSegItemId = payload.RegionalSegItemId;
      var childSegItemId = payload.ChildSegItemId;
      const response = await getMarketDataFusion(
        regionalSegItemId,
        childSegItemId
      );
      commit('setFusionModel', response);
    },
  },
  mutations: {
    setMarketDataModel(state, payload) {
      state.marketDataModel = payload;
    },
    setCurrentlySelectedDataSetType(state, newDataSetTypeId) {
      state.currentFocus.datasetTypeId = newDataSetTypeId;
    },
    setCurrentlySelectedInput(state, payload) {
      state.currentFocus.segmentedDataIndex = payload.SegmentedDataIndex;
      state.currentFocus.regionId = payload.RegionalSegItemId;
      state.currentFocus.childSegItemId = payload.ChildSegItemId;
      state.currentFocus.year = payload.Year;

      if (state.currentFocus.datasetTypeId == 4) {
        // is it the trends tab?
        state.currentFocus.isPercentage = payload.IsPercentage;
      } else {
        state.currentFocus.isPercentage = false;
      }
    },
    setFusionModel(state, payload) {
      switch (state.currentFocus.datasetTypeId) {
        case 2: // requirements
          state.marketDataModel.RequirementsTab.DataVM.Fusion = payload;
          break;
        case 3: // production
          state.marketDataModel.ProductionTab.DataVM.Fusion = payload;
          break;
        case 4: // trends
          state.marketDataModel.TrendsTab.DataVM.Fusion = payload;
          break;
        default:
          console.log('This datasettype is not implemented.');
          break;
      }
    },
    setTrends(state, payload) {
      // We use the currentFocus object to find correct input in the payload, and maintain it's
      // focus.
      var data = payload.DataVM;
      var dataset;
      if (state.currentFocus.segmentedDataIndex == 0) {
        // then it's the regional data
        dataset = state.currentFocus.isPercentage
          ? data.RegionalPercentages
          : data.RegionalDataset;
      } else {
        var segmentedDataset = state.currentFocus.isPercentage
          ? data.SegmentationPercentages
          : data.SegmentationDatasets;
        dataset = segmentedDataset.find(
          (segmentedData) =>
            (segmentedData.SegmentedDataIndex =
              state.currentFocus.segmentedDataIndex)
        );
      }

      // set the seg item data on row.
      var selectedRow = dataset.Rows.find(
        (row) => row.RegionalSegItemId == state.currentFocus.regionId
      );
      if (
        state.currentFocus.childSegItemId != null &&
        state.currentFocus.childSegItemId != ''
      ) {
        selectedRow = selectedRow.SubRows.find(
          (subrow) => subrow.ChildSegItemId == state.currentFocus.childSegItemId
        );
      }

      var selectedInput = selectedRow.DataPoints.find(
        (datapoint) => datapoint.Year == state.currentFocus.year
      );

      // focus that input
      selectedInput.IsFocused = true;

      // Maintain what is currently open / expanded
      data.RegionalDataset.IsOpen =
        state.marketDataModel.TrendsTab.DataVM.RegionalDataset.IsOpen;
      data.RegionalDataset.IsExpanded =
        state.marketDataModel.TrendsTab.DataVM.RegionalDataset.IsExpanded;

      data.RegionalPercentages.IsOpen =
        state.marketDataModel.TrendsTab.DataVM.RegionalPercentages.IsOpen;
      data.RegionalPercentages.IsExpanded =
        state.marketDataModel.TrendsTab.DataVM.RegionalPercentages.IsExpanded;

      for (var i = 0; i < data.SegmentationDatasets.length; i++) {
        data.SegmentationDatasets[i].IsOpen =
          state.marketDataModel.TrendsTab.DataVM.SegmentationDatasets[i].IsOpen;
        data.SegmentationDatasets[i].IsExpanded =
          state.marketDataModel.TrendsTab.DataVM.SegmentationDatasets[
            i
          ].IsExpanded;

        data.SegmentationPercentages[i].IsOpen =
          state.marketDataModel.TrendsTab.DataVM.SegmentationPercentages[
            i
          ].IsOpen;
        data.SegmentationPercentages[i].IsExpanded =
          state.marketDataModel.TrendsTab.DataVM.SegmentationPercentages[
            i
          ].IsExpanded;
      }

      // Now that we have set the focus in the payload, we can set the marketDataModel.TrendsTab value.
      state.marketDataModel.TrendsTab = payload;
    },
  },
};

export default marketDataModule;
