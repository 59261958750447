import { updateUser, getUsers, createUser, getUserById, deleteUser, getUserRoles } from '../../modules/admin/user/services/user.service';

const userModule = {
  state: () => ({
    usersData: null,
    userData: null,
    userRole: null,
    roles: null,

    accessControlUserSort: {
      sortField: null,
      sortOrder: null,
      firstPagValue: null,
      rowsPagValue: null,
    }

  }),
  getters: {
    obtainUsersData(state) { //get users List
      return state.usersData;
    },
    obtainUserData(state) { //get a user
      return state.userData;
    },
    obtainCurrentUserRole(state) { //get userRoles
      return state.userRole;
    },
    obtionUserRoles(state) {//get Roles
      return state.roles
    },
    obtainAccessControlUserSort(state) {
      return state.accessControlUserSort;
    }
  },
  mutations: {
    setUsersData(state, users) { //set Users List
      state.usersData = users;
    },
    setUserData(state, user) { // set a user 
      state.userData = user
    },
    addUser(state, user) {
      state.usersData.push(user);
    },
    removeUser(state, userId) {
      state.usersData = [
        ...state.usersData.filter((u) => u.id !== userId),
      ];
    },
    setCurrentUserRole(state, role) {
      state.userRole = role;
    },
    setUserRoles(state, roles) {
      state.roles = roles
    },
    //AccessControlUSerSort 
    updateAccessControlUserSort(state, accessControlUserSort) {
      state.accessControlUserSort = accessControlUserSort
    }
  },
  actions: {
    async obtainUsersData({ commit }) { // dispatch usersList
      const userData = await getUsers();
      commit('setUsersData', userData);
    },
    async obtainUser({ commit }, userId) { // dispatch a user 
      const userData = await getUserById(userId);
      commit('setUserData', userData)
    },
    async addUser({ commit }, user) {
      const userData = await createUser(user);
      commit('addUser', userData)
    },
    async modifyUserData({ commit }, { user, userId }) {
      const userData = await updateUser(user, userId);
      commit('setUserData', userData);
    },
    async removeUser({ commit }, userId) {
      await deleteUser(userId);
      commit('removeUser', userId)
    },
    async addCurrentUserRole({ commit }, role) {
      commit('setCurrentUserRole', role)
    },
    async obtainUserRoles({ commit }) {
      const roles = await getUserRoles();
      commit('setUserRoles', roles)
    },
    updateAccessControlUserSort({ commit }, accessControlUserSort) {
      commit('updateAccessControlUserSort', accessControlUserSort)
    }
  },
};

export default userModule;
