import { axiosProvider } from '../../../../shared/config/axios.config';
import { createFormData  } from '@/shared/helpers/formData.helper';

async function getDocuments(countryId , companyId ) {
  // console.log('countryId',countryId);
  // console.log('companyId' ,companyId);
  if(!countryId){
    if(!companyId){
      const response = await axiosProvider.get('/documents');
      const documents = response.data;
      return documents;
    }
    else{
      try {
        const response = await axiosProvider.get(`/documents/?companyId=${companyId}`);
        const documents = response.data;
        return documents;
      } catch (error) {
        console.log('error');
        return null;
      }
    }
  }
  else{
    try {
      const response = await axiosProvider.get(`/documents/?countryId=${countryId}`);
      const documents = response.data;
      return documents;
      
    } catch (error) {
      return null
    }
  }
}

async function getDocumentsByCompanyId(companyId , topicId){
  let response ;
  if(topicId){
    response = await axiosProvider.get(`/documents/companies/${companyId}?topicId=${topicId}`);
    const documents = response.data;
    return documents
  }
  else{
    response = await axiosProvider.get(`/documents/companies/${companyId}`);
    const documents = response.data;
    return documents;
  }
}

async function getDocumentByDocumentId(documentId) {
  const response = await axiosProvider.get(`/documents/${documentId}`);
  const documents = response.data;
  return documents;
}

async function getDocumentsByTopicId(topicId, programId) {
  if(!programId){
    // console.log('nodocTopicSegItemsId');
    // console.log(topicId);
    const response = await axiosProvider.get(`/documents/topic/${topicId}`);
    const documents = response.data;
    return documents;
  }
  else{
    // console.log('yesdocTopicSegItemsId');
    // console.log( docTopicSegItemsId)
    const response = await axiosProvider.get(`/documents/topic/${topicId}?programId=${programId}`);
    const documents = response.data;
    return documents;
  }
}
async function createDocument(document) {
  const response = await axiosProvider.post('/documents', document);
  const data = response.data;
  return data;
}

async function editDocument(documentId, document) {
  delete document.documentId;
  const response = await axiosProvider.put(
    `/documents/${documentId}`,
    document
  );
  const data = response.data;
  return data;
}

async function deleteDocument(documentId) {
  const response = await axiosProvider.delete(`/documents/${documentId}`);
  const data = response.data;
  return data;
}

async function getDocumentApplications() {
  const response = await axiosProvider.get('/documentApplications');
  const documentApplications = response.data;
  return documentApplications;
}

async function getDocumentSources() {
  const response = await axiosProvider.get('/documentSources');
  const documentSources = response.data;
  return documentSources;
}

async function getDocumentPrograms() {
  const response = await axiosProvider.get('/documentPrograms');
  const documentPrograms = response.data;
  return documentPrograms;
}

async function createDocumentProgram(newDocumentProgram) {
  const response = await axiosProvider.post('/documentPrograms', newDocumentProgram);
  const documentProgram = response.data;
  return documentProgram;
}

async function editDocumentProgram(documentProgramId, editedDocumentProgram) {
  const response = await axiosProvider.put(`/documentPrograms/${documentProgramId}`, editedDocumentProgram);
  const documentProgram = response.data;
  return documentProgram;
}

async function deleteDocumentProgram(documentProgramId) {
  const response = await axiosProvider.delete(`/documentPrograms/${documentProgramId}`);
  const documentProgram = response.data;
  return documentProgram;
}

async function deleteBulkDocuments(documents){
  const formData =  new FormData()
  createFormData(formData, 'documentsId', documents);
  const formDatajoin = [...formData].map(documentId => documentId.join('='))
  const formDataAndjoin = formDatajoin.join('&')
  let response = null
  if(formDatajoin.length > 1){
    response = await axiosProvider.delete(`/documents/bulk?${formDataAndjoin}` )
  }
  else{
    response = await axiosProvider.delete(`/documents/${documents}`);
  }
  const data = response.data;
  return data
}

// edit Contract document in company
async function editContractDocument(contractId,  contractForm){
  const contract= {
    'contract': contractForm
  }
  const response = await axiosProvider.put(`/documents/contract/${contractId}`, contract)
  return response.data
}

// get Origin, origin dont Have ownComponent, when origin expands, create origin services 
async function getOrigin(){
  // console.log('deberia traer origin');
  const response = await axiosProvider.get('/origin');
  const origin = response.data; 
  return origin 
}


export {
  getDocuments,
  getDocumentsByCompanyId,
  getDocumentByDocumentId,
  getDocumentsByTopicId,
  createDocument,
  editDocument,
  deleteDocument,
  getDocumentApplications,
  getDocumentSources,
  getDocumentPrograms,
  createDocumentProgram,
  editDocumentProgram,
  deleteDocumentProgram,
  deleteBulkDocuments,
  editContractDocument,
  getOrigin,
};
