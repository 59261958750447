import {
  getRegions,
  getRegionTypes,
  createRegion,
  editRegion,
  deleteRegion,
  getRegionCountriesData,
} from '../../modules/admin/region/services/region.service';

const regionModule = {
  state: () => ({
    regions: [],
    regionTypes: [],
    notDefaultRegions: [],
    adminRegionSort: {
      sortField: "name",
      sortOrder: 1,
      firstPagValue: null,
      rowsPagValue: null,
    },
    regionCountriesData: [],
  }),
  getters: {
    obtainRegions(state) {
      return state.regions;
    },
    obtainRegionTypes(state) {
      return state.regionTypes;
    },
    obtainNotDefaultRegion(state) {
      return state.notDefaultRegions;
    },
    obtainAdminRegionSort(state) {
      return state.adminRegionSort;
    },
    obtainRegionCountriesData(state){
      return state.regionCountriesData;
    }
  },
  mutations: {
    setRegions(state, regions) {
      state.regions = regions;
    },
    setRegionTypes(state, regionTypes) {
      state.regionTypes = regionTypes;
    },
    setNotDefaultRegion(state, regions) {
      state.notDefaultRegions = regions;
    },
    addRegion(state, region) {
      state.regions.push(region);
    },
    updateRegion(state, region) {
      state.regions = [
        ...state.regions.filter((t) => t.regionId !== region.regionId),
        region,
      ];
    },
    deleteRegion(state, regionId) {
      state.regions = [
        ...state.regions.filter((t) => t.regionId !== regionId),
      ];
    },
    //AdminRegionSort 
    updateAdminRegionSort(state, adminRegionSort) {
      state.adminRegionSort = adminRegionSort
    },
    // RegionCountriesData
    setRegionCountriesData(state, regionCountriesData){
      state.regionCountriesData = regionCountriesData;
    },
    cleanRegionCountriesData(state){
      state.regionCountriesData = [];
    },
  },
  actions: {
    async obtainRegions({ commit }, { regionTypeId = null, withChildren = false, isDefault = true }) {
      if (isDefault) {
        const regions = await getRegions(regionTypeId, withChildren);
        commit('setRegions', regions);
      }
      else {
        const regions = await getRegions(regionTypeId, withChildren, isDefault);
        commit('setNotDefaultRegion', regions);
      }
    },
    async obtainRegionTypes({ commit }) {
      const regionTypes = await getRegionTypes();
      commit('setRegionTypes', regionTypes);
    },
    async addNewRegion({ commit }, region) {
      const response = await createRegion(region);
      commit('addRegion', response.data);
    },
    async modifyRegion({ commit }, region) {
      const response = await editRegion(region.regionId, region);
      commit('updateRegion', response.data);
    },
    async removeRegion({ commit }, regionId) {
      await deleteRegion(regionId);
      commit('deleteRegion', regionId);
    },
    //AdminRegionSort
    updateAdminRegionSort({ commit }, adminRegionSort) {
      commit('updateAdminRegionSort', adminRegionSort)
    },
    // RegionCountriesData
    async obtainRegionCountriesData({ commit }, { topicId, form}){
      const regionCountriesData = await getRegionCountriesData(topicId, form);
      commit('setRegionCountriesData', regionCountriesData)
    },
    cleanRegionCountriesData({ commit }){
      commit('cleanRegionCountriesData')
    },
  },
};

export default regionModule;
