import { getViewModel, updateDocumentMarketValue } from '../../modules/admin/market-data/pages/document/services/documentMarketVolumes.service';

const marketDataModule = {
  state: () => ({
    years: [],
    rows: [],
  }),
  getters: {
    years: (state) => state.years,
    rows: (state) => state.rows,
  },
  mutations: {
    setYears(state, newYearsArray) {
      state.years = newYearsArray;
    },

    setRows(state, newRows) {
      state.rows = newRows;
    },
  },
  actions: {
    async changeTag({ commit }, documentId) {
      const result = await getViewModel(documentId);
      commit('setYears', result.years);
      commit('setRows', result.rows);
    },
    async changeDocumentMarketValue({ commit }, documentMarketValue) {
      await updateDocumentMarketValue(documentMarketValue);
      const result = await getViewModel(documentMarketValue.documentId);
      commit('setYears', result.years);
      commit('setRows', result.rows);
    },
  },
};

export default marketDataModule;
