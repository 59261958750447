import {
  getTopicById,
  getFilteredTopics,
  getTopics,
  createTopic,
  editTopic,
  deleteTopic,
  getTopicByCompanyId,
  getOutputVersus,
  getOutputValues,
  getSingleOutputValues,
} from '../../modules/admin/topic/services/topic.service';
import { updateUserSelectedTopic } from '../../modules/admin/user/services/user.service';

const topicModule = {
  state: () => ({
    topics: [],
    topic: [],
    topicVersus: [],
    output: [],
    singleOutput: [],
    selectedTopic: null,
    adminTopicSort: {
      sortField: "name",
      sortOrder: 1,
      firstPagValue: null,
      rowsPagValue: null,
    },
    marketDataTopicSort: {
      sortField: "name",
      sortOrder: 1,
      firstPagValue: null,
      rowsPagValue: null,
    }
  }),
  getters: {
    obtainTopics(state) {
      return state.topics;
    },
    obtainTopic(state) {
      return state.topic
    },
    obtainTopicVersus(state) {
      return state.topicVersus
    },
    obtainOutput(state) {
      return state.output
    },
    obtainSingleOutput(state) {
      return state.singleOutput
    },
    obtainSelectedTopic(state) {
      return state.selectedTopic;
    },
    // adminTopicSort
    obtainAdminTopicSort(state) {
      return state.adminTopicSort
    },
    // marketDataTopicsort
    obtainMarketDataTopicSort(state) {
      return state.marketDataTopicSort
    }

  },
  mutations: {
    setTopics(state, topics) {
      state.topics = topics;
    },
    setTopic(state, topic) {
      state.topic = topic;
    },
    setTopicVersus(state, topic) {
      state.topicVersus = topic
    },
    setOutput(state, output) {
      state.output = output
    },
    cleanOutput(state) {
      state.output = [];
    },
    setSingleOutput(state, singleOutput) {
      state.singleOutput = singleOutput
    },
    cleanSingleOutput(state) {
      state.singleOutput = [];
    },
    addTopic(state, topic) {
      state.topics.push(topic);
    },
    updateTopic(state, topic) {
      state.topics = [
        ...state.topics.filter((t) => t.topicId !== topic.topicId),
        topic,
      ];
    },
    deleteTopic(state, topicId) {
      state.topics = [
        ...state.topics.filter((t) => t.topicId !== topicId),
      ];
    },
    setSelectedTopic(state, selectedTopic) {
      state.selectedTopic = selectedTopic;
    },
    cleanSelectedTopic(state) { //for cleaning selectedTopic
      state.selectedTopic = null
    },
    //adminTopicSort 
    updateAdminTopicSort(state, adminTopicSort) {
      state.adminTopicSort = adminTopicSort
    },
    updateMarketDataTopicSort(state, marketDataTopicSort) {
      state.marketDataTopicSort = marketDataTopicSort
    }
  },
  actions: {
    async obtainTopics({ commit }) {
      const topics = await getTopics();
      commit('setTopics', topics);
    },
    cleanTopics({ commit }) {
      commit('setTopics', null);
    },
    async obtainFilteredTopics({ commit }, { totalDocuments, totalPrograms }) {
      const topics = await getFilteredTopics(totalDocuments, totalPrograms);
      commit('setTopics', topics);
    },
    async obtainTopic({ commit }, topicId) {
      const topic = await getTopicById(topicId);
      commit('setTopic', topic)
    },
    async obtainTopicsByCompanyId({ commit }, companyId) {
      const topics = await getTopicByCompanyId(companyId);
      commit('setTopics', topics);
    },
    async obtainTopicVersus({ commit }, topicId) {
      const topics = await getOutputVersus(topicId);
      commit('setTopicVersus', topics)
    },
    async obtainOutput({ commit }, { topicId, form }) {
      const output = await getOutputValues(topicId, form)
      commit('setOutput', output)
    },
    cleanOutput({ commit }) {
      commit('cleanOutput');
    },

    async obtainSingleOutput({ commit }, { topicId, form }) {
      // ToDo Add the integrations From Back
      const output = await getSingleOutputValues(topicId, form);
      commit('setSingleOutput', output)
    },
    cleanSingleOutput({ commit }) {
      commit('cleanSingleOutput');
    },
    async addNewTopic({ commit }, topic) {
      const response = await createTopic(topic);
      commit('addTopic', response.data);
    },
    async modifyTopic({ commit }, topic) {
      const response = await editTopic(topic.topicId, topic);
      commit('updateTopic', response.data);
    },
    async removeTopic({ commit }, topicId) {
      await deleteTopic(topicId);
      commit('deleteTopic', topicId);
    },
    async updateSelectedTopic({ commit }, topicId) {
      const topic = await getTopicById(topicId);
      await updateUserSelectedTopic(topic.topicId);
      localStorage.setItem('topicId-mfkb', JSON.stringify(topic.topicId));
      commit('setSelectedTopic', topic);
    },
    cleanSelectedTopic({ commit }) { //clean SelectedTopic and localstorage TopicId-mfkb
      localStorage.setItem('topicId-mfkb', null);
      commit('cleanSelectedTopic');
    },

    // admin Topic Sort 
    updateAdminTopicSort({ commit }, adminTopicSort) {
      commit('updateAdminTopicSort', adminTopicSort);
    },

    // marketData Topic Sort 
    updateMarketDataTopicSort({ commit }, marketDataTopicSort) {
      commit('updateMarketDataTopicSort', marketDataTopicSort)
    }
  },
};

export default topicModule;
