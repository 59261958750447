import { axiosProvider } from '../../../../../../shared/config/axios.config';

async function getViewModel(documentId) {
  const response = await axiosProvider.get(
    `/documents/marketValueForm/${documentId}`
  );
  const data = response.data;
  return data;
}

async function updateDocumentMarketValue(marketValueModel) {
  const response = await axiosProvider.post(
    `/documents/submitDocMarketValue`,
    marketValueModel
  );
  const data = response.data;
  return data;
}

export { getViewModel, updateDocumentMarketValue };
