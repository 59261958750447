import {
  getSegmentations,
  createSegmentation,
  editSegmentation,
  deleteSegmentation,
  createSegmentationItem,
  editSegmentationOrder,
  editSegmentationItemOrder,
  deleteSegmentationItem,
  editSegmentationItem,
} from '../../modules/admin/segmentation/services/segmentation.service';

const segmentationModule = {
  state: () => ({
    segmentations: [],
  }),
  getters: {
    obtainSegmentations(state) {
      return state.segmentations;
    },
  },
  mutations: {
    setSegmentations(state, segmentations) {
      state.segmentations = segmentations;
    },
    addSegmentation(state, segmentation) {
      state.segmentations.push(segmentation);
    },
    addSegmentationItemToSegmentation(state, segmentationItem) {
      const selectedSegmentation = state.segmentations.find(
        (s) => s.segmentationId == segmentationItem.segmentationId
      );
      if (selectedSegmentation.isRegional) {
        segmentationItem.regionId = parseInt(segmentationItem.regionId);
      }
      selectedSegmentation.segmentationItems.push(segmentationItem);
    },
    updateSegmentation(state, segmentation) {
      state.segmentations = [
        ...state.segmentations.filter(
          (t) => t.segmentationId !== segmentation.segmentationId
        ),
        segmentation,
      ].sort((a, b) => a.order - b.order);
    },
    updateSegmentationItem(state, segmentationItem) {
      if (segmentationItem.regionId) {
        segmentationItem.regionId = parseInt(segmentationItem.regionId);
      }
      const segmentation = state.segmentations.find(
        (s) => s.segmentationId == segmentationItem.segmentationId
      );

      let newSegmentation = [
        ...segmentation.segmentationItems.filter(
          (si) => si.segmentationItemId !== segmentationItem.segmentationItemId
        ),
        segmentationItem,
      ]
      segmentation.segmentationItems = newSegmentation.sort((a,b) => a.order - b.order)
    },
    updateSegmentationOrder(state, segmentation, goUp) {
      let sideSegmentation = state.segmentations.find(
        (t) => t.order !== segmentation.order
      );
      sideSegmentation.order = goUp
        ? sideSegmentation.order - 1
        : sideSegmentation.order + 1;

      state.segmentations = [
        ...state.segmentations.filter(
          (t) =>
            t.segmentationId !== segmentation.segmentationId &&
            t.segmentationId !== sideSegmentation.segmentationId
        ),
        segmentation,
        sideSegmentation,
      ].sort((a, b) => a.order - b.order);
    },

    updateSegmentationItemOrder(state, segmentationItem, goUp){
      console.log(state, segmentationItem, goUp);
    },
    deleteSegmentation(state, segmentationId) {
      state.segmentations = [
        ...state.segmentations.filter(
          (t) => t.segmentationId !== segmentationId
        ),
      ];
    },
    deleteSegmentationItem(state, { segmentationItemId, segmentationId }) {
      let segmentation = state.segmentations.find(
        (s) => s.segmentationId == segmentationId
      );
      segmentation.segmentationItems = [
        ...segmentation.segmentationItems.filter(
          (si) => si.segmentationItemId !== segmentationItemId
        ),
      ];
    },
    // addingRegionCountries
    addRegionCountries(state , {regionCountries , segmentationId, segmentationItemId}){
      let segmentation = state.segmentations.find(s => s.segmentationId === segmentationId)
      let segmentationItem = segmentation.segmentationItems.find(sI=> sI.segmentationItemId === segmentationItemId)
      segmentationItem.region.regionCountries.push(regionCountries)
    },
    removeRegionCountries(state , {regionCountries , segmentationId, segmentationItemId}){
      let segmentation = state.segmentations.find(s => s.segmentationId === segmentationId)
      let segmentationItem = segmentation.segmentationItems.find(sI=> sI.segmentationItemId === segmentationItemId)
      segmentationItem.region.regionCountries = [
        ...segmentationItem.region.regionCountries.filter(
          c=> c.countryId !== regionCountries
        )
      ]
    }
  },
  actions: {
    async obtainSegmentations({ commit }, topicId) {
      let segmentations = await getSegmentations(topicId);
      commit('setSegmentations', segmentations);
    },
    async addNewSegmentation({ commit }, segmentation) {
      const response = await createSegmentation(segmentation);
      commit('addSegmentation', response.data);
    },
    async addNewSegmentationItem({ commit }, segmentationItem) {
      const response = await createSegmentationItem(segmentationItem);
      commit('addSegmentationItemToSegmentation', response.data);
    },
    async modifySegmentation({ commit }, segmentation) {
      const response = await editSegmentation(
        segmentation.segmentationId,
        segmentation
      );
      commit('updateSegmentation', response.data);
    },
    async modifySegmentationOrder({ commit }, { segmentationId, goUp }) {
      const response = await editSegmentationOrder(segmentationId, goUp);
      commit('updateSegmentationOrder', response.data, goUp);
    },
    async modifySegmentationItemOrder({commit}, {segmentationItemId, goUp}){
      const response = await editSegmentationItemOrder(segmentationItemId , goUp);
      console.log('responseEditSegmentationItemOrder',response);
      commit('updateSegmentationItemOrder',segmentationItemId , goUp);
    },
    async removeSegmentation({ commit }, segmentationId) {
      await deleteSegmentation(segmentationId);
      commit('deleteSegmentation', segmentationId);
    },
    async removeSegmentationItem(
      { commit },
      { segmentationItemId, segmentationId }
    ) {
      await deleteSegmentationItem(segmentationItemId);
      commit('deleteSegmentationItem', { segmentationItemId, segmentationId });
    },
    async modifySegmentationItem({ commit }, segmentation) {
      const response = await editSegmentationItem(
        segmentation.segmentationItemId,
        segmentation
      );
      commit('updateSegmentationItem', response.data);
    },
    // add RegionCountries
    // for mutate the segmentation
    async createRegionCountries({commit}, {regionCountries, segmentationItemId , segmentationId}){
      commit('addRegionCountries', {regionCountries, segmentationItemId , segmentationId})
    },
    async deleteRegionCountries({commit}, {regionCountries, segmentationItemId , segmentationId}){
      commit('removeRegionCountries', {regionCountries, segmentationItemId , segmentationId})
    }
  },
};

export default segmentationModule;
