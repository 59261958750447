import { axiosProvider } from "../../../../shared/config/axios.config";

async function getCountries() {
    const response = await axiosProvider.get(`/regions/countries`);
    const data = response.data;
    return data;
}

async function getFilteredCountries(totalDocuments,totalPrograms, hasPriority) {
    const response = await axiosProvider.get(`/regions/countries/filtered?totalDocuments=${totalDocuments}&totalPrograms=${totalPrograms}&hasPriority=${hasPriority}`);
    const data = response.data;
    return data;
}

async function getCountryByCountryId(countryId) {
    const response = await axiosProvider.get(`/regions/country/${countryId}`);
    const data = response.data;
    return data;
}

async function editCountry(countryId, country){
    delete country.countryId
    // console.log(country)
    const response = await axiosProvider.put(`/regions/country/${countryId}`, country);
    const data = response.data;
    return data ;
}

async function getProgramsPerCountry(topicId){
    const response = await axiosProvider.get(`/regions/countries/topic/${topicId}`);
    const programsPerCountry = response.data;
    return programsPerCountry
}

// // need api updated
// // async function getCountriesByRegionId(regionId){
// //     const response = await axiosProvider.get();
// //     const data = response.data;
// //     return data; 
// // } 

// async function createCountry(country){
//     const response = await axiosProvider.post('/regions', country);
//     const data = response.data;
//     return data;
// }



// async function deleteCountry(countryId){
//     const response = await axiosProvider.delete(`/regions/${countryId}`);
//     const data = response.data;
//     return data; 
// }

export {
    getCountries,
    getFilteredCountries,
    getCountryByCountryId,
    editCountry,
    // // getCountriesByRegionId,
    // createCountry,
    // deleteCountry,
    getProgramsPerCountry,
}
