import { axiosProvider } from '../../../../shared/config/axios.config';

async function getMarketData() {
  const response = await axiosProvider.get('/marketData');
  const data = response.data;
  return data;
}

async function getMarketDataFusion(parentSegItemId, childSegItemId) {
  const response = await axiosProvider.get(`/marketData/fusion?parentSegItemId=${parentSegItemId}&childSegItemId=${childSegItemId}`);
  const data = response.data;
  return data;
}

export { getMarketData, getMarketDataFusion };
