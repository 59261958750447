//admin/countries

export const countriesRoutes = [
    {
        path:'',
        name:'CountriesList',
        component:()=> import('./components/CountryList.vue')
    },
    {
        path:'filteredDocProg',
        name:'CountriesFilteredDocProg',
        // after backend get The filtered countries change new Doc
        component:()=> import('./components/CountryList2.vue')
    },
    {
        path:'country/:countryId',
        name: 'EditCountry',
        component:()=> import('./pages/EditCountry.page.vue')
    }
]