import {
    getCategories,
    createCategories,
    editCategory,
    deleteCategory,
} from '../../modules/admin/category/services/category.service';

const categoryModule = {
    state: () => ({
        categories: [],
    }),
    getters: {
        obtainCategories(state) {
            return state.categories;
        },
    },
    mutations: {
        setCategories(state, categories) {
            state.categories = categories;
        },
        addCategory(state, category) {
            state.categories.push(category)
        },
        updateCategory(state, category) {
            state.categories = [
                ...state.categories.filter((item) => item.categoryId !== category.categoryId),
                category,
            ];
        },
        deleteCategory(state, categoryId) {
            state.categories = [
                ...state.categories.filter(item => item.categoryId !== categoryId),
            ];
        },
    },
    actions: {
        async obtainCategories({ commit }) {
            const categories = await getCategories();
            commit('setCategories', categories);
        },
        async addNewCategory({ commit }, category) {
            await createCategories(category);
            commit('addCategory', category);
        },
        async modifyCategory({ commit }, category) {
            await editCategory(category.categoryId, category);
            commit('updateCategory', category)
        },
        async removeCategory({ commit }, categoryId) {
            await deleteCategory(categoryId);
            commit('deleteCategory', categoryId);
        }
    },
}

export default categoryModule;