import { axiosProvider } from '../../../../shared/config/axios.config';
import axios from 'axios';
// remove regionCountry not work with provider
// import axios from 'axios';
// const baseURL = process.env.VUE_APP_API_URL;
// const token = localStorage.getItem('token-mfkb');
// const config = {
//   headers: {
//     'Authorization': `Bearer ${token}`,
//     'Content-Type': 'application/json',
//   }
// }

async function getRegions(regionTypeId = null, withChildren = false, isDefault = true) {
  const response = await axiosProvider.get(`/regions?regionTypeId=${regionTypeId ? regionTypeId : ''}&withChildren=${withChildren}&isDefault=${isDefault}`);
  const data = response.data;
  return data;
}

async function getRegionTypes() {
  const response = await axiosProvider.get('/regions/regionTypes');
  const data = response.data;
  return data;
}

async function getRegionById(regionId) {
  const response = await axiosProvider.get(`/regions/${regionId}`);
  const data = response.data;
  return data;
}

async function createRegion(region) {
  const response = await axiosProvider.post('/regions', region);
  const data = response.data;
  return data;
}

async function editRegion(regionId, region) {
  delete region.regionCountries;
  const response = await axiosProvider.put(`/regions/${regionId}`, region);
  const data = response.data;
  return data;
}

async function deleteRegion(regionId) {
  const response = await axiosProvider.delete(`/regions/${regionId}`);
  const data = response.data;
  return data;
}
// regionCountries 
async function createRegionCountries(regionCountry) {
  try {
    const response = await axiosProvider.post(`/regions/countries`, regionCountry)
    const data = response.data;
    return data;
  } catch (error) {
    return error
  }
}

async function deleteRegionCountries(regionCountry) {
  const response = await axiosProvider.delete(`/regions/countries`, { headers: { "Content-type": "application/json" }, data: JSON.stringify(regionCountry) })
  const data = response.data;
  return data;
}

async function exportToExcelProgramsPerCountry(selectedTopic) {
  // console.log('exportfuncion topicId', selectedTopicId.topicId);
  const topicName = selectedTopic.name.split(' ').join('')
  // console.log(topicName);

  // await axiosProvider.get(`/regions/countries/topic/${selectedTopic.topicId}/export`)
  await axios({
    method: 'get',
    url: process.env.VUE_APP_API_URL + `/regions/countries/topic/${selectedTopic.topicId}/export`,
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token-mfkb')}`,
      'Content-Type': 'application/json;'
    },
    responseType: 'blob'
  })
    .then((response) => {
      const url = URL.createObjectURL(new Blob([response.data], {
        type: 'application/vnd.ms-excel'
      }))
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `ProgramsPerCountry-${topicName}.xlsx`)
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
    });
}

// regionCountriesData
async function getRegionCountriesData(topicId, form) {
  const response = await axiosProvider.post(`/regions/countries/topic/${topicId}/output/values`, form);
  const data = response.data;
  return data;
}

export {
  getRegions,
  getRegionTypes,
  getRegionById,
  createRegion,
  editRegion,
  deleteRegion,
  createRegionCountries,
  deleteRegionCountries,
  exportToExcelProgramsPerCountry,
  getRegionCountriesData,
};

