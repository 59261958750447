import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import Signup from '@/views/Signup.vue';
import Login from '@/views/Login.vue';
import Unauthorized from '@/views/Unauthorized.vue';
import Admin from '@/modules/admin/Admin.module.vue';
import { adminRoutes } from '../modules/admin/admin.route';
import PageNotFound from '@/views/PageNotFound.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: Unauthorized,
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    children: adminRoutes,
    meta: { requiresAuth: true, roles: ['ADMIN', 'SUPERADMIN'] },
  },
  { path: '/:catchAll(.*)', component: PageNotFound },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token-mfkb');
  if (to.meta.requiresAuth) {
    if (!token) {
      next('/login');
    } else {
      if (hasPermission()) {
        next();
      } else {
        next('/unauthorized');
      }
    }
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (
    from.path === '/admin/topics/documents' ||
    from.path === '/admin/topics/companies' ||
    from.path === '/admin/topics/market-volume-data'
  ) {
    localStorage.setItem('lastActiveTopicRoute', from.path);
    next();
  } else {
    next();
  }
});

// TODO FIX PARAMS
router.beforeEach((to, from, next) => {
  let route = '/admin/topics/documents';
  const routeFromStorage = localStorage.getItem('lastActiveTopicRoute');
  if (
    to.name === 'TopicDocuments' &&
    to.query.fromParent &&
    (
      from.path !== '/admin/topics/documents' ||
      from.path !== '/admin/topics/companies' ||
      from.path !== '/admin/topics/market-volume-data') &&
    routeFromStorage
  ) {
    route = routeFromStorage;
    if (
      to.name === 'TopicDocuments' &&
      to.query.fromParent &&
      from.path.includes('/admin/topics/')
    ) {
      next({ name: 'TopicDocuments' });
    } else {
      next(route);
    }
  } else {
    if (
      to.name === 'TopicDocuments' &&
      to.query.fromParent &&
      !to.query.edit
    ) {
      next({ name: 'TopicDocuments' });
    } else {
      next();
    }
  }
});

function hasPermission() {
  // TODO: Check user ROLE/Permission in the API
  return true;
}

export default router;
