import { axiosProvider } from "../../../../shared/config/axios.config";

async function getCategories(){
    const response =await axiosProvider.get(`/categories`);
    const data = response.data
    return data;
}
async function getCategoriesById(categoryId) {
    const response = await axiosProvider.get(`/categories/${categoryId}`);
    const data = response.data
    return data
}

async function createCategories(category){
    const response = await axiosProvider.post(`/categories`,category);
    const data = response.data
    return data;
}

async function editCategory(categoryId , category){
    const response = await axiosProvider.put(`/categories/${categoryId}`, category);
    const data = response.data
    return data
}

async function deleteCategory(categoryId){
    const response = await axiosProvider.delete(`/categories/${categoryId}`);
    const data = response.data;
    return data
}

export {getCategories, createCategories , getCategoriesById ,editCategory ,deleteCategory}