import { login } from '../../shared/services/auth.service';

const authModule = {
  state: () => ({
    user: null,
  }),
  getters: {
    obtainUser(state) {
      const currentUser = state.user !== null
          ? state.user
          : JSON.parse(localStorage.getItem('user-mfkb'));
      return currentUser;
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
  },
  actions: {
    async loginUser({ commit }, userCredentials) {
      const userResponse = await login(userCredentials);
      localStorage.setItem('token-mfkb', userResponse.token);
      localStorage.setItem('user-mfkb', JSON.stringify(userResponse.user));
      localStorage.setItem('topicId-mfkb', JSON.stringify(userResponse.user.selectedTopic));
      localStorage.setItem('role-mfkb', JSON.stringify(userResponse.user.userRoles[0].role.name))
      commit('setUser', userResponse.user);
    },
    async logoutUser({ commit }) {
      localStorage.removeItem('token-mfkb');
      localStorage.removeItem('user-mfkb');
      localStorage.removeItem('topicId-mfkb');
      localStorage.removeItem('role-mfkb');
      commit('setUser', null);
    },
  },
};

export default authModule;
