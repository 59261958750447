function createFormData(formData, key, data) {
  if (data === Object(data) || Array.isArray(data)) {
    for (var i in data) {
      createFormData(formData, key + '[' + i + ']', data[i]);
    }
  } else {
    formData.append(key, data);
  }
}

function createFormData2(formData, key, data) {
  let a = [];
  data.map(item => a.push(Object.values(item)[0]));
  createFormData(formData, key , a )
}

export { createFormData, createFormData2 };
