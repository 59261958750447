<template>
  <!-- <h1>signup</h1> -->
  <div class="container">
    <div class=" row d-flex justify-content-center">
      <div class="col-12 col-md-6 py-4">
        <Button
          class="p-button-raised p-button-rounded my-3 p-button-danger" 
          icon="pi pi-arrow-left"
          @click="goBack" 
        />

        <form @submit.prevent="onSubmit">
          

          <div style="display:flex; justify-content: space-between; ">
              <h1 class="h3 mb-4 fw-normal">
                Create New User
              </h1>
              <!-- <label class="mb-2 md:m-0 p-as-md-center">
                <strong v-if="userForm.analistUser" class="mb-2">Analist User</strong> 
                <strong v-else>Admin User</strong> 
                <InputSwitch v-model="userForm.analistUser" style="margin-left: 0.7rem;"/> 
              </label> -->
          </div>

          <!-- <h1 class="h3 mb-4 fw-normal">
            Sign Up New User
          </h1>
          <div class="d-flex  align-items-start flex-column  mb-4">
            <strong v-if="userForm.analistUser" class="mb-2">Analist User</strong> 
            <strong v-else>Admin User</strong> 
            <InputSwitch v-model="userForm.analistUser" style="margin-left: 0.7rem;"/> 
          </div> -->

          <div class="form-floating mb-4">
            <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com" autocomplete="off"
              required v-model="userForm.email" @blur="v$.userForm.email.$touch" />
            <label for="floatingInput">Email address</label>
            <span v-if="v$.userForm.email.$error" class="text-danger p-2">
              {{ v$.userForm.email.$errors[0].$message}}
              <!-- {{ v$.userForm.email.$error[0].$message}} -->
            </span>
          </div>

          <div class="form-floating mb-4">
            <input type="password" class="form-control" id="floatingPassword" placeholder="Password" autocomplete="off"
              required v-model="userForm.password" @blur="v$.userForm.password.$touch" />
            <label for="floatingPassword">Password</label>
            <span v-if="v$.userForm.password.$error" class="text-danger p-2">
              {{ v$.userForm.password.$errors[0].$message }}
            </span>
          </div>

          <!-- <div class="form-floating mb-4">
            <input type="tel" class="form-control" id="floatingPhoneNumber" placeholder="+123456789" autocomplete="off"
              pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required v-model="userForm.phoneNumber"
              @blur="v$.userForm.phoneNumber.$touch" />
            <label for="floatingPhoneNumber">Phone Number</label>
            <span v-if="v$.userForm.phoneNumber.$error" class="text-danger p-2">
              {{ v$.userForm.phoneNumber.$error[0].$message}}
            </span>
          </div> -->

          <!-- <div class="form-floating mb-4">
            <input 
              type="text" 
              class="form-control" 
              id="floatingUserName" 
              placeholder="UserName" 
              autocomplete="off"
              required 
              v-model="userForm.userName" 
              @blur="v$.userForm.userName.$touch" 
            />
            <label for="floatingUserName">UserName</label>
            <span v-if="v$.userForm.userName.$error" class="text-danger p-2">
              {{ v$.userForm.userName.$error[0].$message}}
            </span>
          </div> -->

          <div class="form-floating mb-4">
            <input type="text" class="form-control" id="floatingFirstName" placeholder="First Name" autocomplete="off"
              required v-model="userForm.firstName" @blur="v$.userForm.firstName.$touch" />
            <label for="floatingFirstName">First Name</label>
            <!-- <span v-if="v$.userForm.firstName.$error" class="text-danger p-2">
              {{ v$.userForm.firstName.$error[0].$message}}
            </span> -->
          </div>

          <div class="form-floating mb-4">
            <input type="text" class="form-control" id="floatingLastName" placeholder="Last Name" autocomplete="off"
              required v-model="userForm.lastName" @blur="v$.userForm.lastName.$touch" />
            <label for="floatingLastName">Last Name</label>
            <!-- <span v-if="v$.userForm.lastName.$error" class="text-danger p-2">
              {{ v$.userForm.lastName.$error[0].$message}}
            </span> -->
          </div>

          <div class="form-floating mb-4">
              <Dropdown 
                v-model="userForm.roles"
                :options="userRoles"
                optionLabel="name"
                optionValue="id"
                class="p-button-outlined me-4"
                style="width: 70%"
                placeholder="Select Roles"
              />
          </div>

          <button @click="signIn()" class="w-100 btn btn-lg btn-outline-primary" type="button"
            :disabled="this.v$.userForm.$invalid || loading">
            <span v-if="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            {{ loading ? 'Loading...' : 'Create User' }}
          </button>

        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';
// import InputSwitch from 'primevue/inputswitch';
import Dropdown from 'primevue/dropdown';
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators'
import { useToast } from 'vue-toastification'
const toast = useToast();

export default {
  name: 'Signup',
  setup: () => ({ v$: useVuelidate() }),
  components: {
    Button,
    // InputSwitch,
    Dropdown,
  },
  data() {
    return {
      userForm: {
        analistUser:true,
        email: null,
        password: null,
        roles:null,
        // phoneNumber: null,
        // userName: null,
        firstName: null,
        lastName: null,
        dateCreated: null,
        dateUpdated: null,
      },
      loading: false,
    };
  },
  validations() {
    return {
      userForm: {
        email: { required, email },
        password: { required },
        // phoneNumber: { required },
        // userName: { required },
        firstName: { required },
        lastName: { required },
      },
    }
  },
  async created() {
    await this.getUserRoles();
  },
  computed:{
    userRoles(){
        return this.$store.getters.obtionUserRoles
    }
  },
  methods: {
    goBack(){
      this.$router.go(-1)
    },
    async getUserRoles(){
        try{
            this.loading = true; 
            await this.$store.dispatch('obtainUserRoles');
            this.loading = false 
        } catch (error) {
            this.loading= false 
        }
    },
    async signIn() {
      try {
        if (!this.v$.userForm.$invalid) {
          this.loading = true;
          const roles = {
            "roleId" : this.userForm.roles
          }
          const userRoles = []
          userRoles.push(roles)

          const newForm = {
            email: this.userForm.email,
            passwordHash: this.userForm.password,
            userName: this.userForm.email,
            firstName: this.userForm.firstName,
            lastName: this.userForm.lastName,
            userRoles: userRoles
          }
          console.log(newForm)
          
          await this.$store.dispatch('addUser', newForm);
          toast.success(`User Created`);
          this.loading = false;
          // this.$router.push({ path: '/login' })
          this.goBack();
        }
        else {
          toast.error('')
        }
      } catch (error) {
        console.error(error)
        toast.error(error.message);
        this.loading = false;
      }
    }
  },
};
</script>

<style scoped>

</style>
